<template>
  <div class="multiselect">
    <div class="selectBox">
      <select>
        <option>{{selectedItems.length>0?selectedItems.substring(0, selectedItems.length - 2):placeholder}}</option>
      </select>
      <div class="overSelect"></div>
    </div>
    <div v-if="expanded" id="checkboxes" class="checkboxes">
      <label :for="obj.pkCodeId" v-for="(obj,key) in options" :key="key">
        <input
          type="checkbox"
          :id="obj.pkCodeId"
          :name="obj.pkCodeId"
          v-model="model"
          :value="obj.pkCodeId"
        />
        {{obj.name }}
      </label>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  props: {
    options: {
      type: Array,
      default: function() {
        return [];
      }
    },
    placeholder: {
      type: String,
      default: function() {
        return "--- Izaberite ---";
      }
    },
    clearModel: {
      type: Number,
      default: function() {
        return 0;
      }
    },
    includeModel: {
      type: Array,
      default: function() {
        return null;
      }
    }
  },

  data() {
    return {
      created: false,
      expanded: false,
      model: [],
      selectedItems: ""
    };
  },
  created() {
    if (this.includeModel.length > 0) {
      this.model = this.includeModel;
    }
    this.autoClose();
  },
  methods: {
    autoClose() {
      var $this = this;

      document.addEventListener("click", e => {
        if (
          ((e.target.closest(".multiselect") != null &&
            e.target.closest(".checkboxes") == null) ||
            (e.target.closest(".multiselect") == null &&
              e.target.closest(".checkboxes") == null)) &&
          $this.expanded
        ) {
          $this.expanded = false;
        } else if (e.target.closest(".multiselect") != null) {
          $this.expanded = true;
        }
      });
    },
    getNames() {
      this.selectedItems = "";
      this.model.forEach(element => {
        this.options.forEach(item => {
          if (element == item.pkCodeId || element == item.pkCodeId.toString()) {
            this.selectedItems += item.name + ", ";
          }
        });
      });
    }
  },
  watch: {
    options: function() {
      if (this.options.length > 0) {
        this.getNames();
      }
    },
    includeModel: function() {
      if (this.includeModel.length > 0) {
        this.model = this.includeModel;
      }
    },
    model: function() {
      this.$emit("changeModel", this.model);
      this.getNames();
    },
    clearModel: function() {
      this.model = [];
      this.selectedItems = "";
    }
  }
};
</script>

<style lang="scss" scoped>
.multiselect {
}

.selectBox {
  position: relative;
}

.selectBox select {
  width: 100%;
}

.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.checkboxes {
  display: block;
  position: absolute;
  top: 54px;
  right: 0px;
  left: 0px;
  z-index: 99;
  background-color: white;
  padding: 2px;
  border: 1px solid #8dc34f;
  label {
    color: black !important;
  }
}

.checkboxes label {
  display: block;
}

.checkboxes label:hover {
  background-color: #1e90ff;
}
</style>