<template>
  <div class="container">
    <div class="header card">
      <h1>
        <span v-lang="'victims.victims'"></span>
      </h1>
      <div class="actions">
        <i
          v-tooltip="$lang('victims.export_excel')"
          class="icon-microsoftexcel"
          v-on:click="exportTableToExcel('tblData')"
        ></i>

        <router-link
          class="primary"
          v-if="$role(20269)"
          :to="{ name: 'VictimsForm' }"
          v-lang="'general.label_add'"
        ></router-link>
      </div>
    </div>

    <!-- <Loader2 v-if="!codesLoaded" /> -->
    <div class="card">
      <form action class="form" v-on:submit.prevent="searchFunction()">
        <div class="input m-0">
          <label for="number">
            <span v-lang="'victims.number'"></span>
          </label>
          <input
            class="m-0"
            v-model="searchModel.number"
            type="text"
            name="number"
            autocomplete="off"
          />
        </div>
        <div class="input m-0">
          <label for="fkInstitutionId">
            <span v-lang="'victims.institution'"></span>
          </label>
          <select
            v-model="searchModel.fkInstitutionId"
            name="fkInstitutionId"
            id="dropdownStatusCode"
            :disabled="disabledInstitution"
          >
            <option value="0" selected v-lang="'victims.placeholder_for_institution'"></option>
            <option
              v-for="(obj, key) in codesModel.institutionCodes"
              :key="key"
              :value="obj.id"
            >{{ obj.name }}</option>
          </select>
        </div>
        <div class="input m-0">
          <label for="fkInstitutionTypeCodeId">
            <span v-lang="'victims.institution_type'"></span>
          </label>
          <select
            v-model="searchModel.fkInstitutionTypeCodeId"
            name="fkInstitutionTypeCodeId"
            id="dropdownStatusCode"
          >
            <option value="0" selected v-lang="'victims.placeholder_for_institution_type'"></option>
            <option
              v-for="(obj, key) in codesModel.institutionTypeCodes"
              :key="key"
              :value="obj.pkCodeId"
            >{{ obj.name }}</option>
          </select>
        </div>
        <div class="input m-0">
          <label for="fkGenderCodeId">
            <span v-lang="'victims.gender'"></span>
          </label>
          <select
            v-model="searchModel.fkGenderCodeId"
            name="fkGenderCodeId"
            id="dropdownStatusCode"
          >
            <option value="0" selected v-lang="'victims.placeholder_for_gender'"></option>
            <option
              v-for="(obj, key) in codesModel.genderCodes"
              :key="key"
              :value="obj.pkCodeId"
            >{{ obj.name }}</option>
          </select>
        </div>
        <div class="input m-0">
          <label for="fkYearsCodeId">
            <span v-lang="'victims.years'"></span>
          </label>
          <select v-model="searchModel.fkYearsCodeId" name="fkYearsCodeId" id="dropdownStatusCode">
            <option :value="0" selected v-lang="'victims.placeholder_for_years'"></option>
            <option
              v-for="(obj, key) in codesModel.yearCodes"
              :key="key"
              :value="obj.pkCodeId"
            >{{ obj.name }}</option>
          </select>
        </div>
        <div class="input m-0">
          <label for="fkCitizenshipCodeId">
            <span v-lang="'victims.citizenship'"></span>
          </label>
          <select
            v-model="searchModel.fkCitizenshipCodeId"
            name="fkCitizenshipCodeId"
            id="dropdownStatusCode"
          >
            <option value="0" selected v-lang="'victims.placeholder_for_citizenship'"></option>
            <option
              v-for="(obj, key) in codesModel.countryCodes"
              :key="key"
              :value="obj.pkCodeId"
            >{{ obj.name }}</option>
          </select>
        </div>
        <div class="input m-0">
          <label for="date">
            <span v-lang="'victims.registration_date_from'"></span>
          </label>
          <date-picker
            name="registrationDate"
            v-model="searchModel.registrationDateFrom"
            :inputClass="'input-date-picker'"
            :locale="lang"
            :initDate="false"
          ></date-picker>
        </div>
        <div class="input m-0">
          <label for="date">
            <span v-lang="'victims.registration_date_to'"></span>
          </label>
          <date-picker
            name="registrationDate"
            v-model="searchModel.registrationDateTo"
            :inputClass="'input-date-picker'"
            :locale="lang"
            :initDate="false"
          ></date-picker>
        </div>
        <div class="input m-0">
          <label for="createdBy">
            <span v-lang="'victims.author'"></span>
          </label>
          <select v-model="searchModel.createdBy" name="createdBy" id="dropdownStatusCode">
            <option value="0" selected v-lang="'victims.placeholder_for_author'"></option>
            <option
              v-for="(obj, key) in codesModel.authorCodes"
              :key="key"
              :value="obj.id"
            >{{ obj.name }}</option>
          </select>
        </div>
        <div class="input m-0">
          <label for="fkInstitutionId">
            <span v-lang="'victims.exploitation_mode'"></span>
          </label>
          <MultiSelect
            :clearModel="rndNumber"
            :showCustomMultiSelect="true"
            :placeholder="$lang('victims.placeholder_exploitation_mode')"
            :options="codesModel.exploitationModeCodes"
            :includeModel="searchModel.exploitationMode"
            @changeModel="changeexploitationModeCodes"
          />
        </div>
        <div class="submit">
          <button
            type="button"
            class="warning"
            v-on:click="resetSearch($event)"
            v-lang="'general.button_reset'"
          ></button>&nbsp;
          <button class="primary" v-lang="'general.button_search'"></button>
        </div>
      </form>
    </div>

    <Loader2 v-if="!resultsLoaded" />
    <span v-else>
      <NoData v-if="!victims || victims.length == 0"></NoData>
      <div v-else class="scroll card table-container">
        <table style="display: none;" id="tblData">
          <tr>
            <td
              align="left"
              width="180"
              style="width:135pt; color: black;"
            >{{ $lang("victims.number") }}</td>
            <td align="left" width="180" style="width:135pt; color: black;">{{ searchModel.number }}</td>
          </tr>
          <tr>
            <td
              align="left"
              width="180"
              style="width:135pt; color: black;"
            >{{ $lang("victims.institution") }}</td>
            <td align="left" width="180" style="width:135pt; color: black;">{{ institution }}</td>
          </tr>
          <tr>
            <td
              align="left"
              width="180"
              style="width:135pt; color: black;"
            >{{ $lang("victims.institution_type") }}</td>
            <td align="left" width="180" style="width:135pt; color: black;">{{ institutionType }}</td>
          </tr>
          <tr>
            <td
              align="left"
              width="180"
              style="width:135pt; color: black;"
            >{{ $lang("victims.gender") }}</td>
            <td align="left" width="180" style="width:135pt; color: black;">{{ gender }}</td>
          </tr>
          <tr>
            <td
              align="left"
              width="180"
              style="width:135pt; color: black;"
            >{{ $lang("victims.years") }}</td>
            <td align="left" width="180" style="width:135pt; color: black;">{{ year }}</td>
          </tr>
          <tr>
            <td
              align="left"
              width="180"
              style="width:135pt; color: black;"
            >{{ $lang("victims.citizenship") }}</td>
            <td align="left" width="180" style="width:135pt; color: black;">{{ citizenship }}</td>
          </tr>
          <tr>
            <td
              align="left"
              width="180"
              style="width:135pt; color: black;"
            >{{ $lang("victims.registration_date_from") }}</td>
            <td align="left" width="180" style="width:135pt; color: black;">
              {{
              searchModel.registrationDateFrom != null
              ? moment(searchModel.registrationDateFrom).format(
              "DD.MM.YYYY."
              )
              : ""
              }}
            </td>
          </tr>
          <tr>
            <td
              align="left"
              width="180"
              style="width:135pt; color: black;"
            >{{ $lang("victims.registration_date_to") }}</td>
            <td align="left" width="180" style="width:135pt; color: black;">
              {{
              searchModel.registrationDateTo != null
              ? moment(searchModel.registrationDateTo).format("DD.MM.YYYY.")
              : ""
              }}
            </td>
          </tr>
          <tr>
            <td
              align="left"
              width="180"
              style="width:135pt; color: black;"
            >{{ $lang("victims.author") }}</td>
            <td align="left" width="180" style="width:135pt; color: black;">{{ author }}</td>
          </tr>
          <tr>
            <td
              align="left"
              width="180"
              style="width:135pt; color: black;"
            >{{ $lang("victims.exploitation_mode") }}</td>
            <td align="left" width="180" style="width:135pt; color: black;">{{ formatExploatation }}</td>
          </tr>
          <tr>
            <td align="left" width="180" style="width:135pt; color: black;"></td>
            <td
              align="left"
              width="180"
              style="width:135pt; color: black;"
            >{{ $lang("victims.number") }}</td>
            <td
              align="left"
              width="250"
              style="width:150pt; color: black;"
            >{{ $lang("victims.registration_date") }}</td>
            <td
              align="left"
              width="250"
              style="width:200pt; color: black;"
            >{{ $lang("victims.institution") }}</td>
            <td
              align="left"
              width="180"
              style="width:200pt; color: black;"
            >{{ $lang("victims.institution_type") }}</td>
            <td
              align="left"
              width="180"
              style="width:135pt; color: black;"
            >{{ $lang("victims.gender") }}</td>
            <td
              align="left"
              width="180"
              style="width:135pt; color: black;"
            >{{ $lang("victims.years") }}</td>
            <td
              align="left"
              width="180"
              style="width:135pt; color: black;"
            >{{ $lang("victims.citizenship") }}</td>
            <td
              align="left"
              width="180"
              style="width:135pt; color: black;"
            >{{ $lang("victims.victim_type") }}</td>
            <td
              align="left"
              width="180"
              style="width:135pt; color: black;"
            >{{ $lang("victims.recruitment_country") }}</td>
            <td
              align="left"
              width="180"
              style="width:135pt; color: black;"
            >{{ $lang("victims.destination_country") }}</td>
            <td
              align="left"
              width="180"
              style="width:135pt; color: black;"
            >{{ $lang("victims.exploitation_mode") }}</td>
            <td
              align="left"
              width="180"
              style="width:135pt; color: black;"
            >{{ $lang("victims.author") }}</td>
          </tr>
          <tr v-for="(obj, key) in victimsForExport" :key="key">
            <td
              height="20"
              class="xl66"
              align="left"
              width="21"
              style="height:15.0pt; width:16pt"
              data-v-5dfcdece
            >{{ key + 1 + (searchModel.pageNum - 1) * searchModel.pageOffset }}</td>
            <td
              height="20"
              class="xl66"
              align="left"
              width="21"
              style="height:15.0pt; width:16pt"
              data-v-5dfcdece
            >{{ obj.number }}</td>
            <td
              height="20"
              class="xl66"
              align="left"
              width="21"
              style="height:15.0pt; width:16pt"
              data-v-5dfcdece
            >
              {{
              obj.registrationDate != null
              ? $moment(obj.registrationDate).format("DD.MM.YYYY.")
              : ""
              }}
            </td>
            <td
              height="20"
              class="xl66"
              align="left"
              width="21"
              style="height:15.0pt; width:16pt"
              data-v-5dfcdece
            >{{ obj.institution }}</td>
            <td
              height="20"
              class="xl66"
              align="left"
              width="21"
              style="height:15.0pt; width:16pt"
              data-v-5dfcdece
            >{{ obj.institutionType }}</td>
            <td
              height="20"
              class="xl66"
              align="left"
              width="21"
              style="height:15.0pt; width:16pt"
              data-v-5dfcdece
            >{{ obj.gender }}</td>
            <td
              height="20"
              class="xl66"
              align="left"
              width="21"
              style="height:15.0pt; width:16pt"
              data-v-5dfcdece
            >{{ obj.years }}</td>
            <td
              height="20"
              class="xl66"
              align="left"
              width="21"
              style="height:15.0pt; width:16pt"
              data-v-5dfcdece
            >{{ obj.citizenship }}</td>
            <td
              height="20"
              class="xl66"
              align="left"
              width="21"
              style="height:15.0pt; width:16pt"
              data-v-5dfcdece
            >{{ obj.victimType }}</td>
            <td
              height="20"
              class="xl66"
              align="left"
              width="21"
              style="height:15.0pt; width:16pt"
              data-v-5dfcdece
            >{{ obj.recruitmentCountry }}</td>
            <td
              height="20"
              class="xl66"
              align="left"
              width="21"
              style="height:15.0pt; width:16pt"
              data-v-5dfcdece
            >{{ obj.destinationCountry }}</td>
            <td
              height="20"
              class="xl66"
              align="left"
              width="21"
              style="height:15.0pt; width:16pt"
              data-v-5dfcdece
            >{{ obj.exploatations }}</td>
            <td
              height="20"
              class="xl66"
              align="left"
              width="21"
              style="height:15.0pt; width:16pt"
              data-v-5dfcdece
            >{{ obj.author }}</td>
          </tr>
        </table>

        <table class="table-hover">
          <thead>
            <tr :id="tableId">
              <th class="text-align-center">#</th>
              <th v-on:click="sort('Number')" Number>
                {{ $lang("victims.number") }}
                <span></span>
              </th>
              <th v-on:click="sort('RegistrationDate')" RegistrationDate>
                {{ $lang("victims.registration_date") }}
                <span></span>
              </th>
              <th v-on:click="sort('Institution')" Institution>
                {{ $lang("victims.institution") }}
                <span></span>
              </th>
              <th v-on:click="sort('FkInstitutionTypeCodeId')" FkInstitutionTypeCodeId>
                {{ $lang("victims.institution_type") }}
                <span></span>
              </th>
              <th v-on:click="sort('FkGenderCodeId')" FkGenderCodeId>
                {{ $lang("victims.gender") }}
                <span></span>
              </th>
              <th v-on:click="sort('FkYearsCodeId')" FkYearsCodeId>
                {{ $lang("victims.years") }}
                <span></span>
              </th>
              <th v-on:click="sort('FkCitizenshipCodeId')" FkCitizenshipCodeId>
                {{ $lang("victims.citizenship") }}
                <span></span>
              </th>
              <th v-on:click="sort('FkVictimTypeCodeId')" FkVictimTypeCodeId>
                {{ $lang("victims.victim_type") }}
                <span></span>
              </th>
              <th v-on:click="sort('FkRecruitmentCountryCodeId')" FkRecruitmentCountryCodeId>
                {{ $lang("victims.recruitment_country") }}
                <span></span>
              </th>
              <th v-on:click="sort('FkDestinationCountryCodeId')" FkDestinationCountryCodeId>
                {{ $lang("victims.destination_country") }}
                <span></span>
              </th>
              <th>
                {{ $lang("victims.exploitation_mode") }}
                <span></span>
              </th>
              <th v-on:click="sort('Author')" Author>
                {{ $lang("victims.author") }}
                <span></span>
              </th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(obj, key) in victims">
              <tr :key="key">
                <td>
                  {{
                  key + 1 + (searchModel.pageNum - 1) * searchModel.pageOffset
                  }}
                </td>
                <td>{{ obj.number }}</td>
                <td>
                  {{
                  obj.registrationDate != null
                  ? $moment(obj.registrationDate).format("DD.MM.YYYY.")
                  : ""
                  }}
                </td>
                <td>{{ obj.institution }}</td>
                <td>{{ obj.institutionType }}</td>
                <td>{{ obj.gender }}</td>
                <td>{{ obj.years }}</td>
                <td>{{ obj.citizenship }}</td>
                <td>{{ obj.victimType }}</td>
                <td>{{ obj.recruitmentCountry }}</td>
                <td>{{ obj.destinationCountry }}</td>
                <td>{{ obj.exploatations }}</td>
                <td>{{ obj.author }}</td>

                <td>
                  <a
                    v-if="$role(20275)"
                    class="default small"
                    v-on:click="victimDetails(obj.pkFormVictimsId)"
                    v-lang="'general.label_details'"
                  ></a>
                  <a
                    v-if="$role(20273)"
                    class="primary small"
                    v-on:click="editVictim(obj.pkFormVictimsId)"
                    v-lang="'general.label_edit'"
                  ></a>
                  <a
                    v-if="$role(20274)"
                    class="error small"
                    v-on:click="deleteVictim(obj)"
                    v-lang="'general.label_delete'"
                  ></a>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <div class="pagination-container">
        <paginate
          v-if="victims.length != 0 && pageCount > 1"
          :page-count="pageCount"
          :click-handler="changePage"
          v-model="searchModel.pageNum"
          :prev-text="this.$lang('general.previous')"
          :next-text="this.$lang('general.next_page')"
          :container-class="'paggination'"
        ></paginate>
      </div>
    </span>
  </div>
</template>

<script>
import Vue from "vue";
import { lang } from "@/global-functions";
import Loader2 from "@/components/General/Loader2";
import NoData from "@/components/General/NoData";
import Paginate from "vuejs-paginate";
import store from "./store";
import globalStore from "@/store";
import MultiSelect from "@/components/General/MultiSelectList";
import moment from "moment";

export default Vue.extend({
  components: {
    Loader2,
    NoData,
    paginate: Paginate,
    MultiSelect
  },
  data() {
    return {
      moment: moment,
      rndNumber: 0,
      codesLoaded: false,
      resultsLoaded: false,
      victims: [],
      pageCount: 0,
      searchModel: {
        number: this.$route.query.number != "" ? this.$route.query.number : "",
        fkInstitutionId:
          this.$route.query.fkInstitutionId > 0
            ? Number(this.$route.query.fkInstitutionId)
            : 0,
        fkInstitutionTypeCodeId:
          this.$route.query.fkInstitutionTypeCodeId > 0
            ? Number(this.$route.query.fkInstitutionTypeCodeId)
            : 0,
        fkGenderCodeId:
          this.$route.query.fkGenderCodeId > 0
            ? Number(this.$route.query.fkGenderCodeId)
            : 0,
        fkYearsCodeId:
          this.$route.query.fkYearsCodeId > 0
            ? Number(this.$route.query.fkYearsCodeId)
            : 0,
        fkCitizenshipCodeId:
          this.$route.query.fkCitizenshipCodeId > 0
            ? Number(this.$route.query.fkCitizenshipCodeId)
            : 0,
        registrationDateFrom:
          this.$route.query.registrationDateFrom != null
            ? new Date(this.$route.query.registrationDateFrom)
            : null,
        registrationDateTo:
          this.$route.query.registrationDateTo != null
            ? new Date(this.$route.query.registrationDateTo)
            : null,
        createdBy:
          this.$route.query.createdBy > 0
            ? Number(this.$route.query.createdBy)
            : 0,
        pageNum:
          this.$route.query.pageNum > 0 ? Number(this.$route.query.pageNum) : 1,
        pageOffset: 10,
        orderColumn:
          this.$route.query.orderColumn != ""
            ? this.$route.query.orderColumn
            : "",
        orderSort:
          this.$route.query.orderSort != "" ? this.$route.query.orderSort : "",
        exploitationMode: []
      },
      victimsForExport: [],
      codesModel: {
        institutionCodes: [],
        institutionTypeCodes: [],
        genderCodes: [],
        yearCodes: [],
        countryCodes: [],
        authorCodes: [],
        exploitationModeCodes: []
      },
      disabledInstitution: false,
      tableId: Math.random(),
      tr: document.getElementById(this.tableId),
      lang:
        globalStore.state.lang == "sr"
          ? "sr-cyrl"
          : globalStore.state.lang == "bs"
          ? "sr"
          : globalStore.state.lang == "hr"
          ? "hr"
          : "en"
    };
  },
  created() {
    if (
      this.$route.query.exploitationMode &&
      !Array.isArray(this.$route.query.exploitationMode)
    ) {
      this.searchModel.exploitationMode.push(
        this.$route.query.exploitationMode
      );
    } else if (Array.isArray(this.$route.query.exploitationMode)) {
      this.searchModel.exploitationMode = [
        ...this.$route.query.exploitationMode
      ];
    }

    this.getCodes();
    setTimeout(() => {
      this.search();
      this.searchForExcel();
    }, 200);
  },
  mounted() {},
  computed: {
    institution() {
      var tmp = "";
      this.codesModel.institutionCodes.forEach(item => {
        if (item.id == this.searchModel.fkInstitutionId) {
          tmp = item.name;
        }
      });
      return tmp;
    },
    institutionType() {
      var tmp = "";
      this.codesModel.institutionTypeCodes.forEach(item => {
        if (item.pkCodeId == this.searchModel.fkInstitutionTypeCodeId) {
          tmp = item.name;
        }
      });
      return tmp;
    },
    gender() {
      var tmp = "";
      this.codesModel.genderCodes.forEach(item => {
        if (item.pkCodeId == this.searchModel.fkGenderCodeId) {
          tmp = item.name;
        }
      });
      return tmp;
    },
    year() {
      var tmp = "";
      this.codesModel.yearCodes.forEach(item => {
        if (item.pkCodeId == this.searchModel.fkYearsCodeId) {
          tmp = item.name;
        }
      });
      return tmp;
    },
    citizenship() {
      var tmp = "";
      this.codesModel.countryCodes.forEach(item => {
        if (item.pkCodeId == this.searchModel.fkCitizenshipCodeId) {
          tmp = item.name;
        }
      });
      return tmp;
    },
    author() {
      var tmp = "";
      this.codesModel.authorCodes.forEach(item => {
        if (item.id == this.searchModel.createdBy) {
          tmp = item.name;
        }
      });
      return tmp;
    },
    formatExploatation() {
      var stringTmp = "";
      this.codesModel.exploitationModeCodes.forEach(item => {
        if (this.searchModel.exploitationMode.includes(item.pkCodeId)) {
          stringTmp += item.name + ",";
        }
      });

      return stringTmp;
    }
  },
  watch: {
    "victims.length": function(n, o) {
      if (this.victims.length == 0 && this.searchModel.pageNum > 1) {
        this.searchModel.pageNum--;
        this.search();
      } else if (n < o) {
        this.search();
      }
    }
  },
  methods: {
    exportTableToExcel(tableID) {
      var downloadLink;
      var worksheetName = this.moment().format("DD_MM_YYYY_HH_mm");
      var dataType = "application/vnd.ms-excel;charset=utf-8;";
      var tableSelect = document.getElementById(tableID);

      var tableHTML = (
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>' +
        worksheetName +
        "</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body>" +
        tableSelect.outerHTML +
        "</body></html>"
      ).replace(/ /g, "%20");

      // Specify file name
      var filename =
        "Žrtve" + "_" + this.moment().format("DD_MM_YYYY_HH_mm") + ".xls";

      // Create download link element
      downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(["\ufeff", tableHTML], {
          type: dataType
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = "data:" + dataType + ", " + tableHTML;
        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
    },
    changeexploitationModeCodes: function(value) {
      this.searchModel.exploitationMode = value;
    },
    sort(columnName) {
      if (this.searchModel.orderColumn != columnName) {
        this.searchModel.orderColumn = columnName;
        this.searchModel.orderSort = "DESC";
      } else if (this.searchModel.orderSort == "DESC") {
        this.searchModel.orderSort = "ASC";
      } else {
        this.searchModel.orderSort = "DESC";
      }
      this.search();
    },
    setIcon() {
      var element = document.getElementById(this.tableId);
      if (element == null) return;
      var array = element.children;
      var item;
      for (let index = 0; index < array.length; index++) {
        if (
          array[index].hasAttribute(
            this.searchModel.orderColumn
              ? this.searchModel.orderColumn.replace(".", "-")
              : this.searchModel.orderColumn
          )
        ) {
          item = array[index];
        }
      }
      if (
        this.searchModel.orderColumn != "" &&
        this.searchModel.orderSort == "DESC"
      ) {
        item.classList.add("desc");
      } else if (this.searchModel.orderSort == "ASC") {
        item.classList.add("asc");
      }
    },
    initSearchModel() {
      this.rndNumber = Math.random();
      this.searchModel.number = null;
      this.searchModel.fkInstitutionId = this.disabledInstitution
        ? this.searchModel.fkInstitutionId
        : 0;
      this.searchModel.fkInstitutionTypeCodeId = 0;
      this.searchModel.fkGenderCodeId = 0;
      this.searchModel.fkCitizenshipCodeId = 0;
      this.searchModel.registrationDate = "";
      this.searchModel.createdBy = 0;
      this.searchModel.pageNum = 1;
      this.searchModel.pageOffset = 10;
      this.searchModel.orderColumn = "";
      this.searchModel.orderSort = "";
      this.searchModel.registrationDateFrom = "";
      this.searchModel.registrationDateTo = "";
      this.searchModel.exploitationMode = [];
    },
    changePage: function(pageNum) {
      this.searchModel.pageNum = pageNum;
      this.search();
    },
    resetSearch() {
      this.initSearchModel();
      this.search();
    },
    searchFunction() {
      this.searchModel.pageNum = 1;
      this.search();
    },
    search() {
      this.searchForExcel();
      this.$router.push({
        query: { ...this.$route.query, ...this.searchModel }
      });
      var $this = this;
      $this.resultsLoaded = false;
      store
        .dispatch("SEARCH_VICTIM", this.searchModel)
        .then(response => {
          $this.victims = response.data.list;
          response.data.list.length > 0
            ? ($this.pageCount = response.data.list[0].pageCount)
            : ($this.pageCount = 0);
          $this.resultsLoaded = true;
          setTimeout(() => {
            $this.setIcon();
          }, 50);
        })
        .catch(error => {
          this.$store.commit("ERROR_MESSAGE", this.error_a);
        });
    },
    searchForExcel() {
      var $this = this;
      var model = JSON.parse(JSON.stringify(this.searchModel));
      model.pageNum = 1;
      model.pageOffset = 10000;
      store
        .dispatch("SEARCH_VICTIM", model)
        .then(response => {
          $this.victimsForExport = response.data.list;
        })
        .catch(error => {
          this.$store.commit("ERROR_MESSAGE", this.error_a);
        });
    },
    getCodes() {
      this.codesLoaded = false;
      store
        .dispatch("GET_CODES_DROPDOWN")
        .then(response => {
          this.codesModel = response.data.model;
          if (response.data.model.institutionId != 5) {
            this.disabledInstitution = true;
            this.searchModel.fkInstitutionId =
              response.data.model.institutionId;
          }
          this.codesLoaded = true;
        })
        .catch(error => {});
    },
    deleteVictim(obj) {
      var $this = this;
      this.$removeToast();
      Vue.toasted.show(lang("victims.do_you_want_to_delete_this_victim"), {
        theme: "toasted-primary",
        position: "top-center",
        duration: 5000,
        action: [
          {
            text: lang("general.label_yes"),
            onClick: (e, toastObject) => {
              store
                .dispatch("DELETE_VICTIM", obj.pkFormVictimsId)
                .then(response => {
                  // $this.documents.splice($this.documents.indexOf(obj), 1);
                  $this.search();
                  $this.$success("victims.victim_successfully_deleted");
                  $this.notifyKey += 1;
                })
                .catch(error => {
                  $this.$error("victims.victim_delete_failed");
                });
              toastObject.goAway(0);
            }
          },
          {
            text: lang("general.label_no"),
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            }
          }
        ]
      });
    },
    editVictim(id) {
      this.$router.push({
        name: "VictimsForm",
        query: { pkFormVictimsId: id }
      });
    },
    victimDetails(id) {
      this.$router.push({
        name: "VictimsDetails",
        params: { id: id }
      });
    }
  }
});
</script>

<style lang="scss" scoped>
.form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  .submit {
    grid-column: 1/4;
  }
  .fieldset {
    grid-column: 1/4;
    .fieldset-container {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
.form.small {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  .submit {
    grid-column: 1/3;
  }
  .fieldset {
    grid-column: 1/3;
    .fieldset-container {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 1fr 1fr;
    }
  }
}
.actions {
  display: flex;
  align-items: center;
  i {
    font-size: 2em;
    margin-right: 10px;
    cursor: pointer;
  }
  .icon-file-pdf {
    color: red;
  }
}
</style>
